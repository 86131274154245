import { useEffect, useState } from "react";
import COUNTRIES_WITH_REGIONS from "../data/COUNTRIES_WITH_REGIONS";

export function useLocationSelector() {
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedRegion, setSelectedRegion] = useState(null); // например: Алматы
    const [selectedCity, setSelectedCity] = useState(null);     // например: Бостандык

    const [regionsMap, setRegionsMap] = useState(null);  // список регионов
    const [citiesData, setCitiesData] = useState(null);  // список городов внутри региона

    // При выборе страны
    useEffect(() => {
        if (!selectedCountry) {
            setRegionsMap(null);
            setCitiesData(null);
            setSelectedRegion(null);
            setSelectedCity(null);
            return;
        }

        const country = COUNTRIES_WITH_REGIONS.find(
            (c) => c.value === selectedCountry.value
        );

        const regionMap = country?.regions?.reduce((acc, region) => {
            acc[region.value] = { label: region.label };
            return acc;
        }, {}) ?? {};

        setRegionsMap(regionMap);
        setSelectedRegion(null);
        setSelectedCity(null);
    }, [selectedCountry]);

    // При выборе региона
    useEffect(() => {
        if (!selectedCountry || !selectedRegion) {
            setCitiesData(null);
            return;
        }

        const loadCities = async () => {
            const data = await import("../data/regions_with_cities.js");
            const regionEntry = data.default?.[selectedCountry.value];
            setCitiesData(regionEntry || {});
        };

        loadCities();
        setSelectedCity(null);
    }, [selectedRegion, selectedCountry]);

    return {
        selectedCountry,
        setSelectedCountry,
        selectedRegion,
        setSelectedRegion,
        selectedCity,
        setSelectedCity,
        regionsMap,
        citiesData
    };
}