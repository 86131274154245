import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./AuthSignup.module.css";
import COUNTRIES_WITH_REGIONS from "../../data/COUNTRIES_WITH_REGIONS";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

async function registerLegalEntity(formData) {
  try {
    const response = await fetch(`${API_BASE_URL}:8080/api/v1/auth/registerLegalEntity`, {
      method: 'POST',
      body: formData,
      headers: {
        "Accept": "application/json",
      }
    });

    const result = await response.json();
    console.log("📩 Response:", result);

    if (!response.ok) {
      const errorDetails = result?.message || response.statusText;
      throw new Error(`Error: ${response.status} - ${errorDetails}`);
    }

    return result;
  } catch (error) {
    console.error('❌ Error during registration:', error);
    throw error;
  }
}
async function registerIndividual(data) {
  console.log("📤 Sending request to:", `${API_BASE_URL}:8080/api/v1/auth/registerIndividual`);
  console.log("📤 Request body:", JSON.stringify(data, null, 2));

  try {
    const response = await fetch(`${API_BASE_URL}:8080/api/v1/auth/registerIndividual`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
      },
      body: JSON.stringify(data)
    });

    console.log("📩 Response received:", response);
    console.log("📩 Response status:", response.status, response.statusText);
    console.log("📩 Response headers:", [...response.headers.entries()]);

    const textResponse = await response.text();
    console.log("📩 Raw response:", textResponse);

    let result;
    try {
      result = JSON.parse(textResponse);
    } catch (error) {
      console.error("❌ JSON parsing error:", error);
      result = { message: 'Invalid JSON response' };
    }

    if (!response.ok) {
      throw new Error(`Error: ${result.message || response.statusText}, Data: ${result.data || ''}`);
    }

    console.log("✅ Successfully registered:", result);
    return result.message;
  } catch (error) {
    console.error('❌ Error during registration:', error);
    throw error;
  }
}

const validateImage = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) return reject();

    const allowedTypes = ["image/png", "image/jpeg"];
    if (!allowedTypes.includes(file.type)) {
      alert("Ошибка: Только PNG или JPG!");
      return reject();
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      if (img.width > 1024 || img.height > 1024) {
        alert("Ошибка: Изображение больше 1024x1024!");
        reject();
      } else {
        resolve();
      }
    };

    img.onerror = () => {
      alert("Ошибка при загрузке изображения!");
      reject();
    };
  });
};

const AuthSignup = () => {
  const [userType, setUserType] = useState("");
  const [loginFrom, setLoginFrom] = useState("");
  const [loginSet, setLoginSet] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agreement, setAgreement] = useState(false);
  const [registrationDocumentFile, setRegistrationDocumentFile] = useState(null);
  const [companyLogoFile, setCompanyLogoFile] = useState(null);
  const [discount, setDiscount] = useState("");
  const navigate = useNavigate();

  const handleFileChange = async (e, setFile) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      await validateImage(file);
      setFile(file);
    } catch {
      setFile(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match!"); // TODO: Добавить отображение ошибок
      return;
    }

    try {
      let response;
      if (userType === "LEGAL_ENTITY") {
        const formData = new FormData();
        formData.append("inviter", loginFrom);
        formData.append("login", loginSet);
        formData.append("country", country);
        formData.append("phoneNumber", phone);
        formData.append("city", city);
        formData.append("email", email);
        formData.append("hashedPassword", password);
        formData.append("discount", discount);
        formData.append("registrationDocument", registrationDocumentFile);
        formData.append("companyLogo", companyLogoFile);

        const result = await registerLegalEntity(formData);

        alert("Signup successful! " + result);
        navigate("/auth");
      }
      else if (userType === "INDIVIDUAL") {
        const data = {
          inviter: loginFrom,
          login: loginSet,
          country,
          phoneNumber: phone,
          city,
          email,
          hashedPassword: password,
        };

        console.log(data);

        const result = await registerIndividual(data);
        alert("Signup successful! " + result);
        navigate("/auth");
      } else {
        alert("Please select a user type.");
        return;
      }

      if (response && response.ok) {
        const result = await response.text();
        alert("Signup successful! " + result);
        navigate("/auth");
      } else if (response) {
        const error = await response.text();
        alert("Signup failed! " + error);
      }
    } catch (error) {
      console.error("Error during signup:", error);
      alert("An error occurred during signup.");
    }
  };

  useEffect(() => {
    const foundCountry = COUNTRIES_WITH_REGIONS.find(c => c.value === country);
    const regionMap = foundCountry?.regions || [];
    setRegionsList(regionMap);
    setRegion("");
    setCity("");
    setCitiesList([]);
  }, [country]);

  useEffect(() => {
    if (!country || !region) return;

    const loadCities = async () => {
      const data = await import("../../data/regions_with_cities.js");
      const cities = data.default?.[country]?.[region]?.cities || [];
      setCitiesList(cities.map((c) => ({ value: c, label: c })));
    };

    loadCities();
    setCity("");
  }, [region, country]);

  const [regionsList, setRegionsList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);

  return (
    <div className={styles.container_authSignup}>
      <div className={styles.container_authSignup_inner}>
        <h3>Регистрация</h3>
        <form onSubmit={handleSubmit} className={styles.container_authSignup_inner_form}>
          <select value={userType} onChange={(e) => setUserType(e.target.value)} className={styles.authsignup_input}>
            <option value="">Выбор</option>
            <option value="INDIVIDUAL">Физическое лицо</option>
            <option value="LEGAL_ENTITY">Юридическое лицо</option>
          </select>

          <input
            type="text"
            placeholder="Логин от которого услышали"
            value={loginFrom}
            onChange={(e) => setLoginFrom(e.target.value)}
            className={styles.authsignup_input}
          />

          <input
            type="text"
            placeholder="Установите свой логин"
            value={loginSet}
            onChange={(e) => setLoginSet(e.target.value)}
            className={styles.authsignup_input}
          />

          <input
            type="tel"
            placeholder="Номер в котором WhatsApp, Telegram"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className={styles.authsignup_input}
          />

          {/* Страна */}
          <select value={country} onChange={(e) => setCountry(e.target.value)} className={styles.authsignup_input}>
            <option value="">Выберите страну</option>
            {COUNTRIES_WITH_REGIONS.map((c) => (
              <option key={c.value} value={c.value}>
                {c.label}
              </option>
            ))}
          </select>

          {/* Регион */}
          <select value={region} onChange={(e) => setRegion(e.target.value)} className={styles.authsignup_input} disabled={!country}>
            <option value="">Выберите регион</option>
            {regionsList.map((r) => (
              <option key={r.value} value={r.value}>
                {r.label}
              </option>
            ))}
          </select>

          {/* Город */}
          <select value={city} onChange={(e) => setCity(e.target.value)} className={styles.authsignup_input} disabled={!region}>
            <option value="">Выберите город</option>
            {citiesList.map((c) => (
              <option key={c.value} value={c.value}>
                {c.label}
              </option>
            ))}
          </select>

          <input
            type="email"
            placeholder="Введите электронную почту"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.authsignup_input}
          />

          <div className={styles.formGroup}>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={styles.authsignup_input}
            />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className={styles.authsignup_input}
            />
          </div>

          {userType === "LEGAL_ENTITY" && (
            <>
              <input
                type="file"
                onChange={(e) => setRegistrationDocumentFile(e.target.files[0])}
                className={styles.authsignup_input_file}
              />

              <input
                type="file"
                onChange={(e) => handleFileChange(e, setCompanyLogoFile)}
                className={styles.authsignup_input_file}
              />

              <input
                type="number"
                placeholder="Скидка"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
                className={styles.authsignup_input}
              />
            </>
          )}

          <label className={styles.authsignup_checkboxLabel}>
            <input
              type="checkbox"
              checked={agreement}
              onChange={(e) => setAgreement(e.target.checked)}
              className={styles.authsignup_checkbox}
            />
            Я согласен(-на) с условиями
          </label>

          <button type="submit" disabled={!agreement || !password || !confirmPassword} className={styles.submitButton}>
            Продолжить
          </button>
        </form>
      </div>
    </div>
  );
};

export default AuthSignup;