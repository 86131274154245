import React, { useEffect, useState } from 'react';
import styles from './Sidebar.module.css';
import axios from 'axios';

export default function Sidebar({ onChatSelect }) {
    const [chats, setChats] = useState([]);

    useEffect(() => {
        const fetchChats = async () => {
            try {
                const { data } = await axios.get('http://localhost:4000/chats');
                setChats(data.chats);
            } catch (err) {
                console.error('Ошибка при получении чатов:', err);
            }
        };

        fetchChats();
    }, []);

    // в компоненте Sidebar:
    const handleLogout = async () => {
        if (window.confirm('Вы действительно хотите выйти?')) {
            try {
                await axios.post('http://localhost:4000/logout');
                alert('Успешный выход!');
                window.location.reload();
            } catch (error) {
                alert('Ошибка выхода: ' + error.message);
            }
        }
    };

    return (
        <div className={styles.sidebar}>
            <div className={styles.header}>
                <h3>Чаты</h3>
                <div className={styles.actions}>
                    <button onClick={handleLogout}>⋮</button>
                </div>
            </div>

            <input className={styles.searchBar} type="text" placeholder="🔎 Поиск" />

            <div className={styles.filterTabs}>
                <button className={styles.active}>Все</button>
                <button>Непрочитанное</button>
                <button>Избранное</button>
                <button>Группы</button>
            </div>

            <div className={styles.chatList}>
                {chats.map((chat, i) => (
                    <div
                        className={styles.chatItem}
                        key={i}
                        onClick={() =>
                            onChatSelect({
                                id: chat.id._serialized, // 👈 только id
                                name: chat.name || chat.id.user,
                            })
                        }
                    >
                        <div>
                            <strong>{chat.name || chat.id.user}</strong>
                            <p>{chat.lastMessage?.body || 'Нет сообщений'}</p>
                        </div>
                        <div>
                            <small>{chat.timestamp || ''}</small>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}