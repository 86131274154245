import React, { useRef, useState, useEffect } from "react";
import styles from './Products.module.css';

import thunderIcon from '../../assets/svg/Products/lightning.png'; // Импортируем картинку
import thunderIcon2 from '../../assets/svg/Products/lightning2.png'; // Импортируем картинку
import thunderIcon3 from '../../assets/svg/Products/lightning3.png'; // Импортируем картинку
import heartIcon from '../../assets/svg/Products/heart.png'; // Импортируем картинку
import fireIcon from '../../assets/svg/Products/fire.png'; // Импортируем картинку
import crownIcon from '../../assets/svg/Products/crown.png'; // Импортируем картинку

import COUNTRIES_WITH_REGIONS from "../../data/COUNTRIES_WITH_REGIONS.js"; // для Dropdown страны и регионов
import { categoriesData, subCategoriesData, thirdCategoriesData } from "../../data/categoriesData.js";
import { CURRENCIES } from "../../data/currencies";
import { mockSavedDataList } from "../../data/mockSavedData";

import Dropdown from "../../components/Dropdown/Dropdown";
import SavedTextContainer from "./SavedTextContainer";
import { useLocationSelector } from "../../hooks/useLocationSelector";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Products = () => {
  const [savedDataList, setSavedDataList] = useState(mockSavedDataList); // массив объектов

  const [selectedCurrency, setSelectedCurrency] = useState(CURRENCIES[0]); // По умолчанию KZT
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedThirdCategory, setSelectedThirdCategory] = useState(null);
  const ref = useRef();

  const {
    selectedCountry,
    setSelectedCountry,
    selectedRegion,
    setSelectedRegion,
    selectedCity,
    setSelectedCity,
    regionsMap,
    citiesData
  } = useLocationSelector();

  const [isEditing, setIsEditing] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(""); // Текущее введенное число
  const [phoneNumbers, setPhoneNumbers] = useState([]); // Список номеров
  const [editingIndex, setEditingIndex] = useState(null); // Индекс редактируемого номера
  const validatePhoneNumber = (number) => {
    const phoneRegex = /^\+?\d{7,15}$/;
    return phoneRegex.test(number);
    //✅ Разрешает номера от 7 до 15 цифр
    //✅ Может начинаться с +, но не обязательно
    //✅ Не пропускает буквы и спецсимволы
  };
  const addPhoneNumber = () => {
    if (!phoneNumber.trim()) {  // Проверяем на пустоту
      return;
    }
    if (!validatePhoneNumber(phoneNumber)) {
      alert("Ошибка! ❌\n\nТребования к номеру:\n- Должен содержать только цифры и, при необходимости, знак '+' в начале\n- Длина: от 7 до 15 цифр\n- Пробелы и другие символы запрещены\n\nПример правильного номера:\n+123456789");
      return;
    }
    setPhoneNumbers([...phoneNumbers, phoneNumber]);
    setPhoneNumber("");
    setIsEditing(false);
  };
  const saveEditedPhoneNumber = () => {
    if (!phoneNumber.trim()) {  // Проверяем на пустоту
      return;
    }
    if (!validatePhoneNumber(phoneNumber)) {
      alert("Ошибка! ❌\n\nТребования к номеру:\n- Должен содержать только цифры и, при необходимости, знак '+' в начале\n- Длина: от 7 до 15 цифр\n- Пробелы и другие символы запрещены\n\nПример правильного номера:\n+123456789");
      return;
    }
    const updatedNumbers = [...phoneNumbers];
    updatedNumbers[editingIndex] = phoneNumber;
    setPhoneNumbers(updatedNumbers);
    setEditingIndex(null);
  };

  const handleSave = () => {
    const titleInput = document.getElementById("titleInput");
    const textInput = document.getElementById("textInput");
    const priceInput = document.querySelector(".priceInput");

    const newData = {
      category: selectedCategory?.label || "Не выбрано",
      subCategory: selectedSubCategory?.label || "Не выбрано",
      title: titleInput ? titleInput.value : "Не указано",
      description: textInput ? textInput.value : "Не указано",
      country: selectedCountry?.label || "Не выбрано",
      city: selectedCity?.label || "Не выбрано",
      phoneNumbers: phoneNumbers.length > 0 ? phoneNumbers.join(", ") : "Не указано",
      price: priceInput ? priceInput.value : "0",
      currency: selectedCurrency?.label || "KZT"
    };

    setSavedDataList((prev) => [...prev, newData]); // добавляем в список
  };

  return (
    <>
      <section className={styles.mailingContainer}>
        <section className={styles.inputContainer}>
          {/* Категория */}
          <Dropdown
            label="Выберите категорию"
            options={categoriesData}
            selectedOption={selectedCategory?.label}
            onSelect={(option) => {
              setSelectedCategory(option);
              setSelectedSubCategory(null);
              setSelectedThirdCategory(null); // сбрасываем третий уровень
            }}
          />

          {/* Подкатегория */}
          {selectedCategory && (
            <Dropdown
              label="Выберите подкатегорию"
              options={subCategoriesData[selectedCategory.value] || []}
              selectedOption={selectedSubCategory?.label}
              onSelect={(option) => {
                setSelectedSubCategory(option);
                setSelectedThirdCategory(null); // сбрасываем третий при смене подкатегории
              }}
            />
          )}

          {/* Третья категория */}
          {selectedSubCategory && thirdCategoriesData[selectedSubCategory.value] && (
            <Dropdown
              label="Выберите детальную категорию"
              options={thirdCategoriesData[selectedSubCategory.value]}
              selectedOption={selectedThirdCategory?.label}
              onSelect={(option) => setSelectedThirdCategory(option)}
            />
          )}

          {/* Загрузка изображения */}
          <label className={styles.fileUpload}>
            <input type="file" accept="image/*" className={styles.fileInput} />
            <span>📷 Загрузите изображение</span>
          </label>

          {/* Загрузка видео */}
          <label className={styles.fileUpload}>
            <input type="file" accept="video/mp4" className={styles.fileInput} />
            <span>🎥 Загрузите видео (mp4, 20 сек, 480p)</span>
          </label>

          {/* Поля для заголовка и описания */}
          <textarea
            maxLength="150"
            id="titleInput"
            placeholder="Введите Заголовок объявления"
            style={{
              width: '-webkit-fill-available',
              height: '20px'
            }}
          ></textarea>

          <textarea
            maxLength="150"
            id="textInput"
            placeholder="Введите описание для объявления, 150 символов"
            style={{
              width: '-webkit-fill-available',
              height: '91px'
            }}
          ></textarea>

          <div>
            <Dropdown
              label="Выберите страну"
              options={COUNTRIES_WITH_REGIONS}
              selectedOption={selectedCountry?.label}
              onSelect={setSelectedCountry}
            />

            {selectedCountry && regionsMap && (
              <Dropdown
                label="Выберите регион"
                options={Object.entries(regionsMap).map(([key, val]) => ({
                  value: key,
                  label: val.label
                }))}
                selectedOption={selectedRegion?.label}
                onSelect={setSelectedRegion}
              />
            )}

            {selectedCountry && selectedRegion && citiesData && (
              <Dropdown
                label="Выберите город"
                options={(citiesData[selectedRegion.value]?.cities || []).map((city) => ({
                  value: city,
                  label: city
                }))}
                selectedOption={selectedCity?.label}
                onSelect={setSelectedCity}
              />
            )}
          </div>

          <article className={styles.optionBox}>
            <div className={styles.dropdownContainer}>
              {isEditing ? (
                <>
                  <input className={styles.inputField} type="text" placeholder="Введите номер" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} autoFocus />
                  <button className={styles.dropdownButtonList} onClick={addPhoneNumber}>OK</button>
                </>
              ) : (
                <>
                  <button className={styles.dropdownButtonNumber} onClick={() => setIsEditing(true)}>Номер телефона</button>
                  <button className={styles.dropdownButtonList} onClick={() => setIsEditing(true)}>+</button>
                </>
              )}

              <div className={styles.numbersList}>
                {phoneNumbers.map((num, index) => (
                  <div key={index} className={styles.numberItem}>
                    {editingIndex === index ? (
                      <>
                        <input className={styles.inputField} type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} autoFocus />
                        <button className={styles.dropdownButtonList} onClick={saveEditedPhoneNumber}>✔</button>
                      </>
                    ) : (
                      <>
                        <button className={styles.dropdownButtonNumber} onClick={() => { setEditingIndex(index); setPhoneNumber(phoneNumbers[index]); }}>{num}</button>
                        <button className={styles.dropdownButtonList} onClick={() => setPhoneNumbers(phoneNumbers.filter((_, i) => i !== index))}>x</button>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </article>

          {/* Поле для цены */}
          <article className={styles.saveBox}>
            <label className={styles.priceLabel}>
              <span>💰 Цена:</span>
              <input type="number" placeholder="Введите цену" className={styles.priceInput} />
            </label>
          </article>

          {/* Выбор валюты */}
          <Dropdown
            label="Выберите валюту"
            options={CURRENCIES}
            selectedOption={selectedCurrency?.label}
            onSelect={setSelectedCurrency}
          />

          <article className={styles.saveBox}>
            <div className={styles.saveButton} onClick={handleSave}>Сохранить</div>
          </article>

          <article className={styles.paymentBox}>
            <div className={styles.payment}>
              <p className={styles.payment_text}>К оплате</p>
              <input className={styles.payment_input} type="number" value="0" />
              <div className={styles.arrow}>
                <div className={styles.arrowTop}></div>
                <div className={styles.arrowBottom}></div>
              </div>
            </div>
          </article>

          <article className={styles.saveBox}>
            <div className={styles.saveButton}>Перейти к Оплате</div>
          </article>
        </section>

        <div className={styles.serviceContainer}>
          <div className={styles.serviceBlocks}>
            <div className={styles.serviceBlock}>
              <div className={styles.counterBlock}>
                <div className={styles.counterContainer}>
                  <img
                    src={heartIcon}
                    alt="Heart Icon"
                    className={styles.thunderIcon} // Добавляем стиль
                  />
                </div>
                <span className={styles.costText}>Топ сутки 3610 тенге</span>
              </div>
            </div>

            <div className={styles.serviceBlock}>
              <div className={styles.counterBlock}>
                <div className={styles.counterContainer}>
                  <img
                    src={fireIcon}
                    alt="Fire Icon"
                    className={styles.thunderIcon} // Добавляем стиль
                  />
                </div>
                <span className={styles.costText}>Топ неделя 9900 тенге</span>
              </div>
            </div>

            <div className={styles.serviceBlock}>
              <div className={styles.counterBlock}>
                <div className={styles.counterContainer}>
                  <img
                    src={crownIcon}
                    alt="Crown Icon"
                    className={styles.thunderIcon} // Добавляем стиль
                  />
                </div>
                <span className={styles.costText}>Топ месяц 37360 тенге</span>
              </div>
            </div>
          </div>

          <div className={styles.serviceBlocks}>
            <div className={styles.serviceBlock}>
              <div className={styles.counterBlock}>
                <div className={styles.counterContainer}>
                  <img
                    src={thunderIcon}
                    alt="Thunder Icon"
                    className={styles.thunderIcon} // Добавляем стиль
                  />
                </div>
                <span className={styles.costText}>Поднять за сутки 1 раз за 390 тенге</span>
              </div>
            </div>

            <div className={styles.serviceBlock}>
              <div className={styles.counterBlock}>
                <div className={styles.counterContainer}>
                  <img
                    src={thunderIcon2}
                    alt="Thunder Icon"
                    className={styles.thunderIcon} // Добавляем стиль
                  />
                </div>
                <span className={styles.costText}>Поднять за сутки 2 раза за 740 тенге</span>
              </div>
            </div>

            <div className={styles.serviceBlock}>
              <div className={styles.counterBlock}>
                <div className={styles.counterContainer}>
                  <img
                    src={thunderIcon3}
                    alt="Thunder Icon"
                    className={styles.thunderIcon} // Добавляем стиль
                  />
                </div>
                <span className={styles.costText}>Поднять за сутки 3 раза за 1130 тенге</span>
              </div>
            </div>
          </div>

          <div className={styles.savedTextContainers}>
            {savedDataList.map((item, index) => (
              <SavedTextContainer
                key={index}
                data={item}
                index={index}
                onMyOrders={(index) => {
                  console.log("Открыть мои заказы по объявлению:", index);
                }}
                onEdit={(i) => {
                  const dataToEdit = savedDataList[i];
                  // тут можешь вставить setState для редактирования
                  console.log("Редактировать:", dataToEdit);
                }}
                onDelete={(i) => {
                  setSavedDataList((prev) => prev.filter((_, idx) => idx !== i));
                }}
              />
            ))}
          </div>

        </div>
      </section>
    </>
  );
};

export default Products;