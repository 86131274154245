import React, { useEffect, useState } from 'react';
import styles from './WhatsAppContainer.module.css';
import Sidebar from './Sidebar';
import MainArea from './MainArea';
import WhatsAppAuth from './WhatsAppAuth';
import axios from 'axios';

const WhatsAppContainer = () => {
    const [isReady, setIsReady] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedChat, setSelectedChat] = useState(null);

    const accessToken = localStorage.getItem('accessToken');

    useEffect(() => {
        if (!accessToken) {
            setError('❗ Авторизуйтесь, чтобы продолжить.');
            setLoading(false);
            return;
        }

        const checkSession = async () => {
            try {
                const { data } = await axios.get('http://localhost:4000/qr');
                if (data.ready) setIsReady(true);
            } catch (err) {
                setError('Ошибка при проверке сессии');
            } finally {
                setLoading(false);
            }
        };

        checkSession();
    }, [accessToken]);

    if (loading) return <div>Загрузка...</div>;
    if (error) return <div style={{ color: 'red' }}>{error}</div>;

    return (
        <div className={isReady ? styles.container : styles.authWrapper}>
            {isReady ? (
                <div style={{ display: 'flex', width: '100%', height: '500px' }}>
                    <div style={{ flexShrink: 0 }}>
                        <Sidebar onChatSelect={setSelectedChat} />
                    </div>

                    <div style={{ flex: 1, minWidth: 0 }}>
                        <MainArea selectedChat={selectedChat} />
                    </div>
                </div>
            ) : (
                <WhatsAppAuth setIsReady={setIsReady} />
            )}
        </div>
    );

};

export default WhatsAppContainer;