import React, { useState, useEffect, useRef } from "react";
import styles from './AdvertisementFolder.module.css';
import ProductCard from "./ProductCard";
import CartModal from "../AdvertisementFolder/CartModal";
import useCart from "../../hooks/useCart";
import AdsSidebar from '../../components/Ads/AdsSidebar';
import { categoriesData, subCategoriesData, thirdCategoriesData } from "../../data/categoriesData.js";
import searchIcon from '../../assets/svg/AdvertisementFolder/search.png';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Advertisements = () => {
    const [products, setProducts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState("");
    const [selectedThirdCategory, setSelectedThirdCategory] = useState(null);
    const [isOpen, setIsOpen] = useState(false); // Статус открытого / закрытого фильтра

    const {
        cartItems,
        addToCart,
        updateCartItem,
        removeFromCart,
        clearCart,
        totalPrice,
        currency
    } = useCart();

    useEffect(() => {
        fetchProducts();
    }, []);

    // UNUSED 
    const fetchProducts = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}:8085/api/v1/products/list`, {
                method: "GET",
                credentials: "include" // Для передачи куки
            });

            console.log("Response headers:", [...response.headers.entries()]);
            console.log("Response status:", response.status);

            if (!response.ok) throw new Error("Failed to fetch products");
            const data = await response.json();
            setProducts(data);
        } catch (err) {
            console.error("Fetch error:", err);
        }
    };
    const handleBuyProduct = async (productId) => {
        try {
            const token = localStorage.getItem("accessToken");
            if (!token) throw new Error("No access token found");

            const response = await fetch(`${API_BASE_URL}:8085/api/v1/products/purchase`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify({ productId }),
            });

            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(errorMessage);
            }

            alert("Product purchased successfully!");
            fetchProducts();
        } catch (err) {
            console.error(err);
            alert(`Could not purchase product: ${err.message}`);
        }
    };

    // Моковые данные (если API не отвечает)
    const mockData = [
        {
            id: 391941,
            name: "Smartphone X",
            city: "Astana",
            country: "Kazakhstan",
            description: "High-end smartphone with excellent camera and battery life.",
            price: "100",
            currency: "KZT",
            seller: "seller1",
            sellerType: "individual",
            paymentMethods: [
                { name: "Kaspi", installments: true },
                { name: "Freedom", installments: false },
                { name: "Halyk", installments: true }],
            image: "https://img.kwcdn.com/product/fancy/a32f6614-c416-4dbb-a2c3-7a3190a0ff29.jpg?imageView2/2/w/150/q/50/format/webp",
            video: "https://goods-vod.kwcdn.com/goods-video/d5b6769f7df86b575f8f934f7d4b7dd4e0ab0cff.f30.mp4",
            published_at: "2024-03-20",
            watch_count: 120
        },
        {
            id: 391942,
            name: "Gaming Laptop Z",
            city: "Almaty",
            country: "Kazakhstan",
            description: "Powerful gaming laptop with RTX 3080 GPU and 144Hz display.",
            price: "4500",
            currency: "KZT",
            seller: "techworld",
            sellerType: "legal",
            discount: 10,
            paymentMethods: [
                { name: "Kaspi", installments: true },
                { name: "Freedom", installments: false },
                { name: "Halyk", installments: true }
            ],
            image: "https://img.kwcdn.com/product/open/1fe88cf488d04cf781b6867d82972103-goods.jpeg?imageView2/2/w/500/q/60/format/webp",
            video: "https://goods-vod.kwcdn.com/goods-video/477f4de0098cafe0a88c72c963d0e12615589ae1.f30.mp4",
            published_at: "2024-03-19",
            watch_count: 305
        },
        {
            id: 391943,
            name: "Wireless Earbuds Pro",
            city: "Dubai",
            country: "UAE",
            description: "Noise-canceling wireless earbuds with immersive sound quality.",
            price: "200",
            currency: "USD",
            seller: "soundwave",
            sellerType: "individual",
            discount: 10,
            paymentMethods: [
                { name: "Kaspi", installments: false },
                { name: "Freedom", installments: false },
                { name: "Halyk", installments: false }
            ],
            image: "https://img.kwcdn.com/product/fancy/a32f6614-c416-4dbb-a2c3-7a3190a0ff29.jpg?imageView2/2/w/150/q/50/format/webp",
            video: "https://goods-vod.kwcdn.com/goods-video/d5b6769f7df86b575f8f934f7d4b7dd4e0ab0cff.f30.mp4",
            published_at: "2024-03-19",
            watch_count: 305
        },
        {
            id: 391944,
            name: "Smart Watch Ultra",
            city: "New York",
            country: "USA",
            description: "Smart watch with health tracking and 7-day battery life.",
            price: "350",
            currency: "USD",
            seller: "weartech",
            sellerType: "legal",
            discount: 10,
            paymentMethods: [
                { name: "Kaspi", installments: false },
                { name: "Freedom", installments: false },
                { name: "Halyk", installments: false }
            ], image: "https://img.kwcdn.com/product/fancy/a32f6614-c416-4dbb-a2c3-7a3190a0ff29.jpg?imageView2/2/w/150/q/50/format/webp",
            video: "https://goods-vod.kwcdn.com/goods-video/d5b6769f7df86b575f8f934f7d4b7dd4e0ab0cff.f30.mp4",
            published_at: "2024-03-18",
            watch_count: 87
        },
        {
            id: 391945,
            name: "4K Smart TV",
            city: "Tokyo",
            country: "Japan",
            description: "55-inch 4K HDR Smart TV with voice control.",
            price: "1200",
            currency: "JPY",
            seller: "techmart",
            sellerType: "legal",
            discount: 10,
            paymentMethods: [
                { name: "Kaspi", installments: false },
                { name: "Freedom", installments: false },
                { name: "Halyk", installments: false }
            ], image: "https://img.kwcdn.com/product/fancy/a32f6614-c416-4dbb-a2c3-7a3190a0ff29.jpg?imageView2/2/w/150/q/50/format/webp",
            video: "https://goods-vod.kwcdn.com/goods-video/d5b6769f7df86b575f8f934f7d4b7dd4e0ab0cff.f30.mp4",
            published_at: "2024-03-17",
            watch_count: 215
        },
        {
            id: 391946,
            name: "Portable Bluetooth Speaker",
            city: "Berlin",
            country: "Germany",
            description: "Compact and powerful Bluetooth speaker with deep bass.",
            price: "80",
            currency: "EUR",
            seller: "audiotech",
            sellerType: "individual",
            discount: 10,
            paymentMethods: [
                { name: "Kaspi", installments: false },
                { name: "Freedom", installments: false },
                { name: "Halyk", installments: false }
            ], image: "https://img.kwcdn.com/product/fancy/a32f6614-c416-4dbb-a2c3-7a3190a0ff29.jpg?imageView2/2/w/150/q/50/format/webp",
            video: "https://goods-vod.kwcdn.com/goods-video/d5b6769f7df86b575f8f934f7d4b7dd4e0ab0cff.f30.mp4",
            published_at: "2024-03-16",
            watch_count: 430
        },
        {
            id: 391947,
            name: "Electric Scooter",
            city: "Paris",
            country: "France",
            description: "Eco-friendly electric scooter with 25km range per charge.",
            price: "600",
            currency: "EUR",
            seller: "ecoride",
            sellerType: "individual",
            available: false,
            image: "https://img.kwcdn.com/product/fancy/a32f6614-c416-4dbb-a2c3-7a3190a0ff29.jpg?imageView2/2/w/150/q/50/format/webp",
            video: "https://goods-vod.kwcdn.com/goods-video/d5b6769f7df86b575f8f934f7d4b7dd4e0ab0cff.f30.mp4",
            published_at: "2024-03-15",
            watch_count: 152
        },
        {
            id: 391948,
            name: "Professional DSLR Camera",
            city: "London",
            country: "UK",
            description: "High-resolution DSLR camera with 4K video recording.",
            price: "2500",
            currency: "GBP",
            seller: "cameraworld",
            sellerType: "legal",
            available: true,
            image: "https://img.kwcdn.com/product/fancy/a32f6614-c416-4dbb-a2c3-7a3190a0ff29.jpg?imageView2/2/w/150/q/50/format/webp",
            video: "https://goods-vod.kwcdn.com/goods-video/d5b6769f7df86b575f8f934f7d4b7dd4e0ab0cff.f30.mp4",
            published_at: "2024-03-15",
            watch_count: 152
        }
    ];
    const awaitingOrders = [
        {
            id: 1,
            name: "Наушники Beats",
            price: 199,
            quantity: 1,
            currency: "USD",
            city: "Almaty",
            country: "Kazakhstan",
            image: "https://img.kwcdn.com/product/fancy/a32f6614-c416-4dbb-a2c3-7a3190a0ff29.jpg?imageView2/2/w/150/q/50/format/webp",
            address: "ул. Абая, 10"
        }
    ];
    const paidOrders = [
        {
            id: 2,
            name: "Игровая мышь",
            price: 59,
            quantity: 1,
            currency: "USD",
            city: "Astana",
            country: "Kazakhstan",
            image: "https://img.kwcdn.com/product/fancy/a32f6614-c416-4dbb-a2c3-7a3190a0ff29.jpg?imageView2/2/w/150/q/50/format/webp",
            address: "Самовывоз"
        }
    ];

    // Поиск
    const [searchQuery, setSearchQuery] = useState("");
    const filteredData = mockData.filter((product) =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Моя корзина 
    const [isCartModalOpen, setIsCartModalOpen] = useState(false);
    const openCartModal = () => setIsCartModalOpen(true);
    const closeCartModal = () => setIsCartModalOpen(false);

    // Корзина - модалка

    return (
        <>
            <div className={styles.panel_width}>
                <div className={styles.filter_panel}>
                    <div className={styles.filter_panel_header}>
                        <div className={styles.filterHeaderLeft}>
                            <label className={styles.toggleSwitch}>
                                <input type="checkbox" checked={isOpen} onChange={() => setIsOpen(prev => !prev)} />
                                <span className={styles.slider}></span>
                            </label>
                            <h3>Фильтры</h3>
                        </div>

                        <button
                            className={`${styles.cartButton} cart-float-button`}
                            onClick={openCartModal}
                        >
                            <strong>🛒 Моя корзина</strong>
                        </button>
                    </div>

                    {isOpen && (
                        <div className={styles.filter_panel_body}>
                            {/* Меню выбора категории */}
                            <p>
                                Категория{" "}
                                {selectedCategory
                                    ? `(Выбрано: ${categoriesData.find((cat) => cat.value === selectedCategory)?.label
                                    })`
                                    : ""}
                            </p>
                            <div className={styles.filter_panel_menu}>
                                {categoriesData.map((category) => (
                                    <div
                                        key={category.label}
                                        className={`${styles.category_item} ${selectedCategory === category.value ? styles.active : ""}`}
                                        onClick={() => {
                                            if (selectedCategory === category.value) {
                                                setSelectedCategory(null);
                                                setSelectedSubcategory(null);
                                            } else {
                                                setSelectedCategory(category.value);
                                                setSelectedSubcategory(null);
                                            }
                                        }}
                                    >
                                        {category.label}
                                    </div>
                                ))}

                            </div>

                            {selectedCategory && subCategoriesData[selectedCategory] && (
                                <>
                                    <p>
                                        Подкатегория{" "}
                                        {selectedSubcategory
                                            ? `(Выбрано: ${subCategoriesData[selectedCategory].find(
                                                (sub) => sub.value === selectedSubcategory
                                            )?.label
                                            })`
                                            : ""}
                                    </p>

                                    <div className={styles.filter_panel_menuChoices}>
                                        {subCategoriesData[selectedCategory].map((subcategory) => (
                                            <div
                                                key={subcategory.value}
                                                className={`${styles.panel_menChoices_shaffle} ${selectedSubcategory === subcategory.value ? styles.activeSubcategory : ""
                                                    }`}
                                                onClick={() =>
                                                    setSelectedSubcategory((prev) =>
                                                        prev === subcategory.value ? null : subcategory.value
                                                    )
                                                }
                                            >
                                                {subcategory.label}
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}

                            {selectedSubcategory && thirdCategoriesData[selectedSubcategory] && (
                                <>
                                    <p>
                                        Третья категория{" "}
                                        {selectedThirdCategory
                                            ? `(Выбрано: ${thirdCategoriesData[selectedSubcategory].find(
                                                (t) => t.value === selectedThirdCategory
                                            )?.label})`
                                            : ""}
                                    </p>

                                    <div className={styles.filter_panel_menuChoices}>
                                        {thirdCategoriesData[selectedSubcategory].map((item) => (
                                            <div
                                                key={item.value}
                                                className={`${styles.panel_menChoices_shaffle} ${selectedThirdCategory === item.value ? styles.activeSubcategory : ""
                                                    }`}
                                                onClick={() =>
                                                    setSelectedThirdCategory((prev) =>
                                                        prev === item.value ? null : item.value
                                                    )
                                                }
                                            >
                                                {item.label}
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}


                        </div>
                    )}

                    <CartModal
                        isOpen={isCartModalOpen}
                        onClose={closeCartModal}
                        title="🛒 Моя корзина"
                        cartItems={cartItems}
                        removeFromCart={removeFromCart}
                        clearCart={clearCart}
                        totalPrice={totalPrice}
                        currency={currency}
                        awaitingOrders={awaitingOrders}
                        paidOrders={paidOrders}
                        addToCart={addToCart}             // ✅ передаём
                        updateCartItem={updateCartItem}   // ✅ передаём
                    >
                    </CartModal>
                </div>
            </div >

            <div className={styles.container}>
                <div className={styles.productsRow}>
                    <div className={styles.product_search}>
                        <input
                            className={styles.product_searchInput}
                            placeholder="Поиск"
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <img src={searchIcon} alt="Поиск" className={styles.searchIconInside} />
                    </div>

                    <div className={styles.productsList}>
                        {filteredData.map((product) => (
                            <ProductCard
                                key={product.id}
                                product={product}
                                handleBuyProduct={handleBuyProduct}
                                addToCart={addToCart}
                                updateCartItem={updateCartItem}
                            />
                        ))}
                    </div>
                </div>


                <div className={styles.sideBar}>
                    Some content with border lines.
                    <br />
                    Some content with border lines.
                    <br />
                    <br />
                    Some content with border lines.
                    <br />
                    Some content with border lines.
                    <br />
                    Some content with border lines.
                    <br />
                    Some content with border lines.
                    <br />
                </div>
            </div>
        </>
    )
};

export default Advertisements;