import React, { useState, useRef, useEffect } from 'react';
import styles from './Dropdown.module.css';

const Dropdown = ({ label, options, selectedOption, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef();

    // Закрытие при клике вне
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <article className={styles.optionBox} ref={ref}>
            <div className={styles.dropdownContainer}>
                <button className={styles.dropdownButton} onClick={() => setIsOpen(!isOpen)}>
                    {selectedOption || label}
                </button>
                {isOpen && (
                    <ul className={styles.dropdownList}>
                        {options.map((option, index) => (
                            <li
                                key={option.value || index}
                                onClick={() => {
                                    onSelect(option);
                                    setIsOpen(false);
                                }}
                            >
                                {option.label} ({option.value})
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </article>
    );
};

export default Dropdown;