import React, { useState, useRef, useEffect } from "react";
import styles from './Mailing.module.css'; // Изменено на CSS-модуль
import WhatsAppContainer from './WhatsAppContainer';
import AdsSidebar from '../../components/Ads/AdsSidebar';

// Импорт изображений для первой колонны
import importContactsImage from '../../assets/svg/Mailing/import-contacs.svg';
import clock2Image from '../../assets/svg/Mailing/clock2.svg';

// Импорт изображений для второй колонны
import WhatsAppLogo from '../../assets/svg/Mailing/WhatsApp_logo.svg';
import TelegramLogo from '../../assets/svg/Mailing/Telegram-logo.svg';
import SmsLogo from '../../assets/svg/Mailing/sms_logo.svg';

export default function Mailing() {
  const kazakhstanOperators = [
    "7700", "7701", "7702", "7705", "7706", "7707", "7708", "7709",
    "7710", "7711", "7712", "7713", "7714", "7715", "7716", "7717", "7718", "7719",
    "7770", "7771", "7772", "7775", "7776", "7777", "7778", "7779"
  ];
  const [selectedOption, setSelectedOption] = useState("База ad_SERVLET");
  const [selectedModelOption, setSelectedModelOption] = useState("Не выбрано");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState("");
  const [importMode, setImportMode] = useState("textarea"); // "textarea" или "excel"
  const [selectedFile, setSelectedFile] = useState(null);
  const countryOptions = [
    { name: "Не выбрано", code: "", maxLength: 11, operators: [] },

    // СНГ (приоритетные страны)
    {
      name: "Казахстан", code: "+7", maxLength: 10,
      operators: ["700", "701", "702", "705", "706", "707", "708", "709",
        "710", "711", "712", "713", "714", "715", "716", "717",
        "718", "719", "770", "771", "772", "775", "776", "777",
        "778", "779"]
    },
    {
      name: "Россия", code: "+7", maxLength: 10,
      operators: ["900", "901", "902", "903", "904", "905", "906", "908", "909",
        "910", "911", "912", "913", "914", "915", "916", "917", "918",
        "919", "920", "921", "922", "923", "924", "925", "926", "927",
        "928", "929", "930", "931", "932", "933", "934", "936", "937",
        "938", "939", "950", "951", "952", "953", "954", "955", "956",
        "957", "958", "960", "961", "962", "963", "964", "965", "966",
        "967", "968", "969", "970", "971", "972", "973", "974", "975",
        "977", "978", "980", "981", "982", "983", "984", "985", "986",
        "987", "988", "989"]
    },
    {
      name: "Беларусь", code: "+375", maxLength: 9,
      operators: ["29", "33", "44", "25"]
    },
    {
      name: "Украина", code: "+380", maxLength: 9,
      operators: ["39", "50", "63", "66", "67", "68", "73", "89", "91", "92", "93", "94", "95", "96", "97", "98", "99"]
    },
    {
      name: "Азербайджан", code: "+994", maxLength: 9,
      operators: ["50", "51", "55", "70", "77"]
    },
    {
      name: "Армения", code: "+374", maxLength: 8,
      operators: ["91", "93", "94", "95", "96", "97", "98", "99", "77", "43", "47"]
    },
    {
      name: "Грузия", code: "+995", maxLength: 9,
      operators: ["555", "557", "558", "599"]
    },
    {
      name: "Кыргызстан", code: "+996", maxLength: 9,
      operators: ["500", "501", "502", "505", "507", "550", "551", "552", "553", "554", "555", "556", "557", "558", "559"]
    },
    {
      name: "Молдова", code: "+373", maxLength: 8,
      operators: ["60", "69", "79"]
    },
    {
      name: "Таджикистан", code: "+992", maxLength: 9,
      operators: ["50", "51", "52", "53", "55", "77", "90", "91", "92", "93", "98"]
    },
    {
      name: "Туркменистан", code: "+993", maxLength: 8,
      operators: ["61", "62", "63", "64", "65"]
    },
    {
      name: "Узбекистан", code: "+998", maxLength: 9,
      operators: ["90", "91", "93", "94", "95", "97", "98", "99"]
    },

    // Европейский Союз (ЕС)
    { name: "Германия", code: "+49", maxLength: 10, operators: ["151", "152", "155", "157", "159", "160", "162", "163", "170", "171", "172", "173", "174", "175", "176", "177", "178"] },
    { name: "Франция", code: "+33", maxLength: 9, operators: ["6", "7"] },
    { name: "Италия", code: "+39", maxLength: 10, operators: ["320", "322", "330", "333", "335", "338", "340", "347", "348"] },
    { name: "Испания", code: "+34", maxLength: 9, operators: ["6", "7"] },
    { name: "Польша", code: "+48", maxLength: 9, operators: ["50", "51", "53", "57", "60", "66", "69"] },
    { name: "Нидерланды", code: "+31", maxLength: 9, operators: ["6"] },
    { name: "Швеция", code: "+46", maxLength: 9, operators: ["70", "72", "73", "76"] },
    { name: "Австрия", code: "+43", maxLength: 10, operators: ["650", "660", "664", "676", "699"] },
    { name: "Бельгия", code: "+32", maxLength: 9, operators: ["45", "46", "47", "48", "49"] },
    { name: "Чехия", code: "+420", maxLength: 9, operators: ["60", "72", "73", "77"] },
    { name: "Португалия", code: "+351", maxLength: 9, operators: ["91", "92", "93", "96"] },
    { name: "Финляндия", code: "+358", maxLength: 9, operators: ["40", "41", "42", "44", "45", "46", "50"] },
    { name: "Греция", code: "+30", maxLength: 10, operators: ["69"] },
    { name: "Дания", code: "+45", maxLength: 8, operators: ["20", "30", "40", "50", "60"] },
    { name: "Ирландия", code: "+353", maxLength: 9, operators: ["83", "85", "86", "87", "88"] },
    { name: "Венгрия", code: "+36", maxLength: 9, operators: ["20", "30", "31", "50", "70"] },
    { name: "Словакия", code: "+421", maxLength: 9, operators: ["90", "91", "94", "95"] },
    { name: "Словения", code: "+386", maxLength: 8, operators: ["30", "31", "40", "41", "51", "70"] },
    { name: "Латвия", code: "+371", maxLength: 8, operators: ["20", "21", "23", "24", "25", "26", "27"] },
    { name: "Литва", code: "+370", maxLength: 8, operators: ["60", "61", "62", "63", "64", "65", "66"] },
    { name: "Эстония", code: "+372", maxLength: 8, operators: ["50", "51", "52", "53", "55", "56", "57"] },
    { name: "Болгария", code: "+359", maxLength: 9, operators: ["87", "88", "89", "98"] },
    { name: "Румыния", code: "+40", maxLength: 9, operators: ["70", "71", "72", "73", "74", "75", "76", "77"] },
    { name: "Хорватия", code: "+385", maxLength: 9, operators: ["91", "92", "95", "97", "98", "99"] },
    { name: "Люксембург", code: "+352", maxLength: 9, operators: ["621", "691"] },

    // Развитые страны (G7 и ключевые регионы)
    { name: "США", code: "+1", maxLength: 10, operators: ["201", "202", "203", "205", "206", "207", "208", "209", "210", "212", "213", "214", "215", "216", "217", "218"] },
    { name: "Канада", code: "+1", maxLength: 10, operators: ["204", "226", "236", "249", "250", "289", "306", "343", "365", "403", "416", "418", "431", "437", "438", "450", "506", "514", "519", "579", "581", "587", "604", "613", "639", "647", "705", "709", "778", "780", "782", "807", "819", "825", "867", "873", "902", "905"] },
    { name: "Великобритания", code: "+44", maxLength: 10, operators: ["71", "72", "73", "74", "75", "76", "77", "78", "79"] },
    { name: "Австралия", code: "+61", maxLength: 9, operators: ["40", "41", "42", "43", "44", "45", "46", "47", "48", "49"] },
    { name: "Новая Зеландия", code: "+64", maxLength: 9, operators: ["20", "21", "22", "27"] },
    { name: "Япония", code: "+81", maxLength: 10, operators: ["70", "80", "90"] },
    { name: "Южная Корея", code: "+82", maxLength: 9, operators: ["10", "11", "16", "17", "18", "19"] },
    { name: "Сингапур", code: "+65", maxLength: 8, operators: ["8", "9"] },
    { name: "Израиль", code: "+972", maxLength: 9, operators: ["50", "51", "52", "53", "54", "55", "56", "57", "58"] },
    { name: "ОАЭ", code: "+971", maxLength: 9, operators: ["50", "52", "54", "55", "56"] },
    { name: "Турция", code: "+90", maxLength: 10, operators: ["501", "505", "506", "507", "532", "533", "534", "535", "537", "538"] },

  ];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [checkedOption, setCheckedOption] = useState(null);
  // Временные данные (моделируем бэкенд)
  const [savedTexts, setSavedTexts] = useState(["Пример 1", "Пример 2"]);
  // Функция редактирования (заполняет input и удаляет текст)
  const handleEdit = (text) => {
    console.log("Редактирование:", text);
    setSavedTexts(savedTexts.filter((t) => t !== text)); // Удаляем из списка
  };
  // Функция удаления
  const handleDelete = (index) => {
    setSavedTexts(savedTexts.filter((_, i) => i !== index));
  };
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleUpload = () => {
    if (importMode === "textarea") {
      console.log("Загруженные номера:", phoneNumbers.split("\n"));
    } else {
      console.log("Импортирован файл:", selectedFile);
    }
    setIsModalOpen(false);
  };
  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleInputChange = (blockId, event) => {
    let value = event.target.value.replace(/\D/g, ""); // Убираем все нечисловые символы
    value = value ? Math.max(parseInt(value, 10), 0) : 0; // Не даем уйти в минус

    if (blockId === "block1") setCounter1(value);
    if (blockId === "block2") setCounter2(value);
    if (blockId === "block3") setCounter3(value);
  };

  const [counter1, setCounter1] = useState(0);
  const [counter2, setCounter2] = useState(0);
  const [counter3, setCounter3] = useState(0);

  const updateCounter = (blockId, value) => {
    if (blockId === "block1") setCounter1((prev) => Math.max(prev + value, 0));
    if (blockId === "block2") setCounter2((prev) => Math.max(prev + value, 0));
    if (blockId === "block3") setCounter3((prev) => Math.max(prev + value, 0));
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [chatDropdownOpen, setChatDropdownOpen] = useState(false);
  const [selectedService, setSelectedService] = useState('whatsapp');

  const [showChatWrapper, setShowChatWrapper] = useState(false);
  const [animateChatWrapper, setAnimateChatWrapper] = useState(false);
  useEffect(() => {
    if (selectedService === 'whatsapp') {
      setShowChatWrapper(true);
      setTimeout(() => setAnimateChatWrapper(true), 10); // запускаем анимацию после маунта
    } else {
      setAnimateChatWrapper(false);
      setTimeout(() => setShowChatWrapper(false), 300); // скрываем через задержку
    }
  }, [selectedService]);

  const [paidCampaigns, setPaidCampaigns] = useState([
    {
      id: 1,
      service: 'WhatsApp',
      from: '+77070000000',
      toList: ['+77071112233', '+77072223344'],
      text: 'Привет! У нас акция 🎉',
      status: 'готово', // выполняется | выполнено | ошибка
      sent: 0,
      failed: 0
    }
  ]); // список рассылок после оплаты

  const startCampaign = (id) => {
    setPaidCampaigns((prev) =>
      prev.map((c) =>
        c.id === id
          ? { ...c, status: 'выполняется' }
          : c
      )
    );

    // имитация рассылки
    setTimeout(() => {
      setPaidCampaigns((prev) =>
        prev.map((c) =>
          c.id === id
            ? { ...c, status: 'выполнено', sent: c.toList.length, failed: 0 }
            : c
        )
      );
    }, 3000); // 3 сек "выполнение"
  };

  const [expandedCampaignId, setExpandedCampaignId] = useState(null);
  const toggleInfo = (id) => {
    setExpandedCampaignId((prevId) => (prevId === id ? null : id));
  };

  // список рассылок после оплаты
  const [recipientFilter, setRecipientFilter] = useState('all'); // all | success | failed


  return (
    <>

      <section className={styles.mailingContainer}>
        <div className={styles.serviceTabs}>
          <button
            className={selectedService === 'whatsapp' ? styles.activeTab : styles.tab}
            onClick={() =>
              setSelectedService((prev) => (prev === 'whatsapp' ? null : 'whatsapp'))
            }
          >
            WhatsApp
          </button>

          <button
            className={selectedService === 'telegram' ? styles.activeTab : styles.tab}
            onClick={() =>
              setSelectedService((prev) => (prev === 'telegram' ? null : 'telegram'))
            }
          >
            Telegram
          </button>

          <button
            className={selectedService === 'sms' ? styles.activeTab : styles.tab}
            onClick={() =>
              setSelectedService((prev) => (prev === 'sms' ? null : 'sms'))
            }
          >
            SMS
          </button>
        </div>

        {selectedService === 'whatsapp' && (
          <div className={`${styles.chatWrapper} ${animateChatWrapper ? styles.chatVisible : ''}`}>
            <div className={styles.campaignsList}>
              <div className={styles.numberText}>
                Укажите номер, с которого будут рассылаться сообщения. Мы рекомендуем использовать неосновной номер, так как он может быть ограничен входящими сообщениями, но лимит на рассылки сохранится.
              </div>

              {paidCampaigns.map((campaign) => (

                <div key={campaign.id}>
                  <div className={styles.campaignRow}>

                    <div className={styles.campaignProgress}>
                      <div className={styles.progressCircle}>
                        <div className={styles.clockHand}></div>
                      </div>
                      <div className={styles.progressText}>
                        {campaign.sent} / {campaign.toList.length}
                      </div>
                    </div>

                    <button
                      className={styles.infoButton}
                      onClick={() => toggleInfo(campaign.id)
                      }
                    >
                      Инфо
                    </button>

                    <button
                      className={styles.launchButton}
                      onClick={() => startCampaign(campaign.id)}
                      disabled={campaign.status === 'выполняется'}
                    >
                      Запуск
                    </button>

                    <div className={styles.statusNumbers}>
                      ✅ {campaign.sent} &nbsp; ❌ {campaign.failed}
                    </div>
                  </div>

                  {expandedCampaignId === campaign.id && (
                    <div className={styles.campaignDetails}>
                      <div className={styles.campaignInfoGrid}>
                        <div>
                          <strong>Ваш аккаунт:</strong><br />
                          {campaign.accountName || 'Мой аккаунт'}
                        </div>
                        <div>
                          <strong>Ваш номер телефона:</strong><br />
                          {campaign.from}
                        </div>
                        <div>
                          <strong>Сервис:</strong><br />
                          {campaign.service}
                        </div>
                      </div>

                      <h4 className={styles.sectionHeader}>Получатели:</h4>
                      <div className={styles.recipientsGrid}>
                        {(campaign.toList || []).map((number, i) => {
                          const isSuccess = (campaign.successList || []).includes(number);
                          const isFailed = (campaign.failedList || []).includes(number);

                          let statusIcon = '⏳';
                          if (isSuccess) statusIcon = '✅';
                          else if (isFailed) statusIcon = '❌';

                          return (
                            <div key={i} className={styles.recipientRow}>
                              <span className={styles.recipientNumber}>{number}</span>
                              <span className={styles.recipientStatus}>{statusIcon}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}


                </div>
              ))}
            </div>


            <div className={styles.chatBoxWrapper}>
              <WhatsAppContainer />
            </div>
          </div>
        )}


        <div className={styles.inputsBlock}>
          <section className={styles.inputContainer}>
            <h4 className={styles.subsubheading}>Ваш промокод для юридических лиц где вы стали амбассадором и получили контекст можно также продвигать рассылками</h4>

            <textarea
              maxLength="150"
              id="textInput"
              placeholder="Введите текст для рассылки, 150 символов"
              style={{
                width: '-webkit-fill-available',
                height: '91px'
              }}
            ></textarea>

            {/* Выпадающий список стран */}
            <article
              className={styles.optionBox}
              onClick={() => setCheckedOption("dropdown")}
            >
              <label className={styles.label}>
                <input
                  type="checkbox"
                  checked={checkedOption === "dropdown"}
                  onChange={() => setCheckedOption("dropdown")}
                />
              </label>
              <div className={styles.dropdownContainer} ref={dropdownRef}>
                <button
                  className={styles.dropdownButton}
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  ▼ {selectedOption}
                </button>

                {/* Выпадающий список */}
                {isDropdownOpen && (
                  <ul className={styles.dropdownList}>
                    {countryOptions.map((country, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setSelectedOption(country.name);
                          setIsDropdownOpen(false);
                        }}
                      >
                        {country.name} ({country.code})
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </article>

            {/* Импорт базы */}
            <article className={styles.optionBox}>
              <label className={styles.label}>
                <input
                  type="checkbox"
                  checked={checkedOption === "import"}
                  onChange={() => setCheckedOption("import")}
                />
              </label>
              <div className={styles.dropdownContainer}>
                <button
                  className={styles.dropdownButton}
                  onClick={(e) => {
                    e.stopPropagation(); // Предотвращаем конфликт клика
                    setCheckedOption("import");
                    handleOpenModal(); // Открываем модальное окно
                  }}
                >
                  <img className={styles.icon} src={importContactsImage} alt="import-contacs" />
                  Импорт базы
                </button>
              </div>
            </article>

            <article className={styles.optionBox}>
              <label className={styles.label}>
                <input type="checkbox" id="checkbox3" />
              </label>
              <div className={styles.dropdownContainer}>
                <button
                  className={styles.dropdownButton}
                  onClick={(e) => {
                    console.log(e);
                    window.location.href = "https://example.com"; // Заменить на нужную ссылку
                  }}
                >
                  <img className={styles.icon} src={clock2Image} alt="clock2" />
                  Планирование
                </button>
              </div>
            </article>

            <article className={styles.saveBox}>
              <div className={styles.saveButton}>Сохранить</div>
            </article>

            <article className={styles.paymentBox}>
              <div className={styles.payment}>
                <p className={styles.payment_text}>К оплате</p>
                <input className={styles.payment_input} type="number" id="item1" value="0" />
                <div className={styles.arrow}>
                  <div className={styles.arrowTop}></div>
                  <div className={styles.arrowBottom}></div>
                </div>
              </div>
            </article>

            <article className={styles.saveBox}>
              <div className={styles.saveButton}>Перейти к Оплате</div>
            </article>
          </section>

          <div className={styles.serviceContainer}>
            <div className={styles.serviceBlocks}>
              <div className={styles.serviceBlock}>
                <img className={styles.serviceLogo} src={WhatsAppLogo} alt="WhatsApp_logo" />
                <div className={styles.counterBlock} id="block1">
                  <div className={styles.counterContainer}>
                    <button className={styles.counterButton} onClick={() => updateCounter('block1', -100)}>-</button>
                    <input
                      className={styles.counterInput}
                      type="text"
                      value={counter1}
                      onChange={(e) => handleInputChange("block1", e)}
                    />
                    <button className={styles.counterButton} onClick={() => updateCounter('block1', 100)}>+</button>
                  </div>
                  <span className={styles.costText}>Стоимость одной рассылки 50 тенге</span>
                </div>
              </div>

              <div className={styles.serviceBlock}>
                <img className={styles.serviceLogo} src={TelegramLogo} alt="Telegram-logo" />
                <div className={styles.counterBlock} id="block2">
                  <div className={styles.counterContainer}>
                    <button className={styles.counterButton} onClick={() => updateCounter('block2', -100)}>-</button>
                    <input
                      className={styles.counterInput}
                      type="text"
                      value={counter2}
                      onChange={(e) => handleInputChange("block2", e)}
                    />
                    <button className={styles.counterButton} onClick={() => updateCounter('block2', 100)}>+</button>
                  </div>
                  <span className={styles.costText}>Стоимость одной рассылки 50 тенге</span>
                </div>
              </div>

              <div className={styles.serviceBlock}>
                <img className={styles.serviceLogo} src={SmsLogo} alt="sms_logo" />
                <div className={styles.counterBlock} id="block3">
                  <div className={styles.counterContainer}>
                    <button className={styles.counterButton} onClick={() => updateCounter('block3', -100)}>-</button>
                    <input
                      className={styles.counterInput}
                      type="text"
                      value={counter3}
                      onChange={(e) => handleInputChange("block3", e)}
                    />
                    <button className={styles.counterButton} onClick={() => updateCounter('block3', 100)}>+</button>
                  </div>
                  <span className={styles.costText}>Стоимость одной рассылки 50 тенге</span>
                </div>
              </div>

            </div>

            <div id="savedTextContainer" className={styles.savedTextContainer}>
              {savedTexts.map((text, index) => (
                <div key={index} className={styles.savedText}>
                  <span>{text}</span>
                  <button className={styles.editButton} onClick={() => handleEdit(text)}>Редактировать</button>
                  <button className={styles.deleteButton} onClick={() => handleDelete(index)}>Удалить</button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section >

      {/* 👉 Абсолютно позиционированный sidebar */}
      < AdsSidebar />

      {/* Модальное окно */}
      {isModalOpen && (
        <div className={styles.modal} onClick={handleCloseModal}>
          <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
            <span className={styles.close} onClick={handleCloseModal}>
              &times;
            </span>

            <p className={styles.warningText}>
              Соблюдайте правила загрузки контактов в столбец: номера должны быть без знака плюса [+] и начинаться с международного кода страны. Например, Казахстан — [7], Россия — [9] и т. д.
            </p>

            <h3>Выберите страну, к которой принадлежат сотовые номера, которые вы хотите импортировать для рассылки. Если номера не будут соответствовать выбранной стране, система выдаст ошибку.</h3>

            <div className={styles.modelDropdownContainer} ref={dropdownRef}>
              {/* Кнопка выбора */}
              <button
                className={styles.modelDropdownButton}
                onClick={() => setIsModelDropdownOpen(!isModelDropdownOpen)}
              >
                {selectedModelOption} ▼
              </button>

              {/* Выпадающий список */}
              {isModelDropdownOpen && (
                <ul className={styles.modelDropdownList}>
                  {countryOptions.map((country, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        setSelectedModelOption(country.name);
                        setIsDropdownOpen(false);
                      }}
                    >
                      {country.name} ({country.code})
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <h2>Выберите способ ввода</h2>
            <div className={styles.switchButtons}>
              <button
                className={`${styles.switchButton} ${importMode === "textarea" ? styles.active : ""}`}
                onClick={() => setImportMode("textarea")}
              >
                📋 COPY-PASTE
              </button>
              <button
                className={`${styles.switchButton} ${importMode === "excel" ? styles.active : ""}`}
                onClick={() => setImportMode("excel")}
              >
                📂 Импорт Excel
              </button>
            </div>

            {importMode === "textarea" ? (
              <>
                <textarea
                  id="phoneNumbers"
                  placeholder="Пример: 77011234567&#10;77771234567"
                  value={phoneNumbers}
                  onChange={(e) => {
                    const selectedCountry = countryOptions.find(c => c.name === selectedModelOption);
                    const maxLength = selectedCountry ? selectedCountry.maxLength : 11;
                    const validOperators = selectedCountry ? selectedCountry.operators : [];

                    let input = e.target.value.replace(/[^\d\n]/g, ""); // Только цифры и перенос строки
                    let lines = input.split("\n"); // Разбиваем текст по строкам

                    let formattedLines = lines.map(line => {
                      if (line.length < 4) return line; // Если номер еще не дописан, не проверяем

                      // Проверяем код оператора только если строка >= 4 символов
                      if (line.length >= 4) {
                        let prefix = line.slice(0, selectedCountry?.code.length === 3 ? 5 : 4); // 5 символов для кодов типа +375, 4 символа для +7
                        if (validOperators.length > 0 && !validOperators.includes(prefix)) return ""; // Если код оператора неверный, удаляем строку
                      }

                      // Проверяем длину номера (оставляем только полностью введенные номера)
                      if (line.length > maxLength) return line.slice(0, maxLength); // Обрезаем длинные номера
                      if (line.length !== maxLength) return line; // Если номер еще не достиг maxLength, не удаляем

                      return line;
                    });

                    setPhoneNumbers(formattedLines.filter(Boolean).join("\n")); // Фильтруем пустые строки и собираем обратно
                  }}
                ></textarea>

              </>
            ) : (
              <>
                <p>Выберите Excel-файл (.xlsx, .csv) для загрузки контактов</p>
                <input
                  type="file"
                  accept=".xlsx, .csv"
                  className={styles.fileInput}
                  onChange={handleFileUpload}
                />
                {selectedFile && <p className={styles.fileName}>📂 {selectedFile.name}</p>}
              </>
            )}

            <button className={styles.uploadButton} onClick={handleUpload}>
              {importMode === "textarea" ? "📋 Загрузить номера" : "📂 Импортировать файл"}
            </button>
          </div>
        </div>
      )}
    </>
  );
}