import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { QRCodeSVG } from 'qrcode.react';

export default function WhatsAppAuth({ setIsReady }) {
    const [qr, setQr] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchQR = async () => {
            try {
                const { data } = await axios.get('http://localhost:4000/qr');

                if (data.ready) {
                    setIsReady(true); // 🔥 главное изменение
                } else if (data.qr) {
                    setQr(data.qr);
                    setError(null);
                } else {
                    setError('QR-код отсутствует в ответе сервера');
                }
            } catch (error) {
                console.error('Ошибка получения QR:', error);
                setError('Ошибка подключения к серверу');
            }
        };

        fetchQR();
        const interval = setInterval(fetchQR, 3000);
        return () => clearInterval(interval);
    }, [setIsReady]);

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <h2 style={styles.title}>Подключи WhatsApp</h2>

                {error && <div style={styles.error}>{error}</div>}

                {qr ? (
                    <div style={styles.qrWrapper}>
                        <QRCodeSVG value={qr} size={200} />
                        <p style={styles.hint}>
                            Открой WhatsApp на телефоне и отсканируй этот QR-код.
                        </p>
                    </div>
                ) : (
                    !error && <p style={styles.loading}>⏳ Ожидание QR-кода...</p>
                )}
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f0f2f5',
    },
    card: {
        backgroundColor: '#ffffff',
        padding: '32px 40px',
        borderRadius: '12px',
        boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        textAlign: 'center',
        maxWidth: '360px',
        width: '100%',
    },
    title: {
        fontSize: '20px',
        color: '#202c33',
        marginBottom: '20px',
    },
    qrWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '12px',
    },
    hint: {
        fontSize: '14px',
        color: '#54656f',
        marginTop: '12px',
    },
    loading: {
        fontSize: '16px',
        color: '#54656f',
    },
    error: {
        backgroundColor: '#ffe6e6',
        color: '#cc0000',
        padding: '12px',
        borderRadius: '8px',
        marginBottom: '16px',
    },
};