import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import styles from './MainArea.module.css';

export default function MainArea({ selectedChat }) {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [input, setInput] = useState('');
    const messagesRef = useRef(null);
    const bottomRef = useRef(null);

    useEffect(() => {
        if (!selectedChat) return;
        setMessages([]);
        setHasMore(true);
        loadMessages(true); // true = первая загрузка
    }, [selectedChat]);

    const loadMessages = async (initial = false, beforeId = null) => {
        if (loading || !hasMore) return;
        setLoading(true);

        try {
            const url = `http://localhost:4000/messages/${selectedChat.id}`;
            const params = initial ? { limit: 20 } : { before: beforeId, limit: 20 };

            const { data } = await axios.get(url, { params });

            if (data.messages.length === 0) {
                setHasMore(false);
            } else {
                setMessages(prev => initial
                    ? data.messages.reverse()
                    : [...data.messages.reverse(), ...prev]
                );

                if (initial && bottomRef.current) {
                    setTimeout(() => bottomRef.current.scrollIntoView({ behavior: 'auto' }), 0);
                }
            }
        } catch (err) {
            console.error('Ошибка загрузки сообщений:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleScroll = (e) => {
        const el = e.target;
        if (el.scrollTop <= 10 && hasMore && !loading) {
            const firstId = messages[0]?.id?._serialized;
            if (firstId) loadMessages(false, firstId);
        }
    };

    const sendMessage = async () => {
        if (!input.trim()) return;

        try {
            await axios.post('http://localhost:4000/send', {
                number: selectedChat.id,
                message: input.trim(),
            });

            setInput('');
            loadMessages(true); // обновляем сообщения после отправки
        } catch (err) {
            console.error('Ошибка при отправке:', err);
        }
    };

    if (!selectedChat) {
        return (
            <div className={styles.placeholder}>
                <h2>Выберите чат</h2>
            </div>
        );
    }

    return (
        <div className={styles.chatArea}>
            <div className={styles.chatHeader}>
                <h3>{selectedChat.name || 'Неизвестный чат'}</h3>
            </div>

            <div className={styles.messages} onScroll={handleScroll} ref={messagesRef}>
                {messages.map((msg) => (
                    <div
                        key={msg.id._serialized}
                        className={`${styles.message} ${msg.fromMe ? styles.mine : styles.their}`}
                    >
                        {msg.body}
                    </div>
                ))}
                <div ref={bottomRef} />
                {loading && <p style={{ textAlign: 'center' }}>⏳ Загрузка...</p>}
            </div>

            <div className={styles.inputArea}>
                <input
                    className={styles.inputField}
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Введите сообщение..."
                    onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
                />
                <button className={styles.sendButton} onClick={sendMessage}>➤</button>
            </div>
        </div>
    );
}